<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <check-list-form-box
        list-url="/checklist"
        :id="id"
        checklist-type="checklist"
        :form-type="formType"
        @add="onAdd"
        @edit="onEdit"
      >
      </check-list-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import CheckListFormBox from '../../components/CheckListFormBox.vue';

export default {
  name: 'CheckListForm',
  components: {
    CheckListFormBox
  },
  async mounted() {
    if (this.$route.name === 'checklist-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'checklist',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },
    ...mapState('checklist', ['loading'])
  },
  methods: {
    ...mapActions('checklist', ['getOne', 'postOne', 'patchOne']),
    onAdd({ checklist }) {
      this.postOne({
        checklist,
        router,
        redirectUrl: '/checklist'
      });
    },
    onEdit({ checklist }) {
      this.patchOne({
        type: 'checklist',
        id: this.id,
        checklist,
        router,
        redirectUrl: '/checklist'
      });
    }
  }
};
</script>
