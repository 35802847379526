<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <check-list-v2-form-box
        list-url="/checklistV2"
        :id="id"
        checklist-type="checklistV2"
        checkpointTitle="Checkpoints"
        :form-type="formType"
        @add="onAdd"
        @edit="onEdit"
      >
        <!-- :isCheckpointEdit="handleDeleteView" -->
      </check-list-v2-form-box>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import CheckListV2FormBox from '../../components/ChecklistV2/CheckListV2FormBox.vue';

export default {
  name: 'CheckListV2Form',
  components: {
    CheckListV2FormBox
  },
  async mounted() {
    if (this.$route.name === 'checklist-new') {
      this.formType = 'new';
      this.id = null;
      // this.handleDeleteView = true;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;
      // this.handleDeleteView = false;
      await this.getOne({
        type: 'checklistV2',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
      // handleDeleteView: false
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Checklist' : 'Update Checklist';
    },
    ...mapState('checklistV2', ['loading']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('checklistV2', ['getOne', 'postOne', 'patchOne']),
    onAdd({ checklistV2 }) {
      if (checklistV2.checkpoints.some(checkpoint => checkpoint.category === '')) {
        Vue.swal({
          title: 'Checkpoints',
          text: 'At least one checkpoint is required',
          background: this.isDarkMode ? '#464d54' : '#ffffff',
          customClass: {
            title: this.isDarkMode ? 'text-light' : 'text-dark',
            content: this.isDarkMode ? 'text-light' : 'text-dark'
          }
        });
      } else {
        this.postOne({
          checklistV2,
          router,
          redirectUrl: '/checklistV2'
        });
      }
    },

    onEdit({ checklistV2 }) {
      this.patchOne({
        type: 'checklistV2',
        id: this.id,
        checklistV2,
        router,
        redirectUrl: '/checklistV2'
      });
    }
  }
};
</script>
