<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <asset-list-form-box
        list-url="/atf"
        :id="id"
        checklist-type="atf"
        :form-type="formType"
        @add="onAdd"
        @edit="onEdit"
      >
      </asset-list-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import AssetListFormBox from '../../components/AssetTransferNewFormBox.vue';

export default {
  name: 'AssetTransferForm',
  components: {
    AssetListFormBox
  },
  async mounted() {
    if (this.$route.name === 'atf-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;
      await this.getOne({
        type: 'atf',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Asset Transfer Form' : 'Update Asset Transfer Form';
    },
    ...mapState('assettransfer', ['assetList'])
  },
  methods: {
    ...mapActions('assettransfer', ['list', 'postOne', 'getOne', 'patchOne']),

    onAdd({ assettransfer }) {
      this.postOne({
        assettransfer,
        router,
        redirectUrl: '/atf'
      });
    },
    onEdit({ assettransfer }) {
      this.patchOne({
        type: 'assettransfer',
        id: this.id,
        assettransfer,
        router,
        redirectUrl: '/atf'
      });
    }
  }
};
</script>
