<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :disabled="isCompleted"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group id="storeid" label-for="store-id">
              <template v-slot:label>
                From Store Id
                <span class="text-danger">*</span>
              </template>
              <multiselect
                id="to-store-id"
                ref="from-multiselect"
                placeholder="Select Store"
                v-model="form.fromStoreId"
                :disabled="isReceiver"
                :options="fromStoreList.map(e => e.storeid)"
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="storeid" label-for="store-id">
              <template v-slot:label>
                To Store Id
                <span class="text-danger">*</span>
              </template>
              <multiselect
                id="to-store-id"
                ref="to-multiselect"
                placeholder="Select Store"
                v-model="form.toStoreId"
                :disabled="isReceiver"
                :options="toStoreList.map(e => e.storeid)"
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="receipt-id" label-for="receipt-name">
              <a v-if="this.form.receiptImage" :href="this.form.receiptImage" target="_blank">
                <b-img thumbnail width="400" v-if="this.form.receiptImage" :src="this.form.receiptImage" fluid></b-img>
              </a>
              <template v-slot:label>
                Upload Receipt / Voucher
                <span class="text-danger">*</span>
              </template>
              <input v-if="!isReceiver" type="file" @change="uploadFile($event, 'receipt')" />
            </b-form-group>
          </b-col>
        </b-row>

        <h4 class="page-title text-center">Asset Details</h4>
        <b-form-group :disabled="isCompleted">
          <div class="input wrapper items-center" v-for="(input, index) in inputs" :key="index">
            <b-row class="mb-3 border-bottom">
              <b-col>
                <b-row>
                  <b-col sm="12" lg="3">
                    <template labelfor="input-atf">Asset Id </template>
                    <multiselect
                      id="input-atf"
                      placeholder="Select Asset Id"
                      :value="input.assetId"
                      :disabled="isReceiver"
                      :options="assetIdList"
                      @input="event => onAssetIdChange(event, index)"
                      :class="{
                        'box-shadow3': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    >
                    </multiselect>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <template labelfor="input-atf">Description </template>
                    <multiselect
                      id="input-atf"
                      placeholder="Select Description"
                      :value="input.assetDescription"
                      :disabled="isReceiver"
                      :options="assetTransferList"
                      @input="event => onAssetDescriptionChange(event, index)"
                      :class="{
                        'box-shadow3': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    >
                    </multiselect>
                  </b-col>
                  <b-col sm="12" lg="3">
                    <template labelfor="input-assetType">Condition</template>
                    <multiselect
                      id="input-assetType"
                      placeholder="Select Question Type"
                      v-model="input.condition"
                      :disabled="isReceiver"
                      :options="['NEW', 'WORKING', 'DAMAGED']"
                      :class="{
                        'box-shadow3': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    >
                    </multiselect>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col :lg="isReceiver ? '5' : '10'" sm="12"
                    ><b-row
                      ><b-col>
                        <b-form-group id="group-name" label-for="input-question">
                          <template labelfor="input-atf"> Qty By Sender </template>
                          <b-form-input
                            id="input-weightage"
                            :disabled="input._id || isReceiver ? true : false"
                            v-model="input.quantityBySender"
                            placeholder="enter quantity"
                            type="number"
                            step="1"
                            min="0"
                            :class="{
                              'box-shadow3 bg-light text-dark': !isDarkMode,
                              'bg-dark text-light placeholder-light': isDarkMode
                            }"
                          ></b-form-input></b-form-group></b-col
                      ><b-col>
                        <b-form-group id="group-products" label-for="input-products">
                          <a v-if="input.senderImage" :href="input.senderImage" target="_blank">
                            <b-img
                              thumbnail
                              width="100"
                              v-if="input.senderImage"
                              :src="input.senderImage"
                              fluid
                            ></b-img>
                          </a>
                          <template v-slot:label>
                            Upload Sender Side
                            <span class="text-danger">*</span>
                          </template>
                          <input
                            type="file"
                            v-if="!isReceiver"
                            @change="uploadFile($event, 'sender', index)"
                          /> </b-form-group></b-col
                    ></b-row>
                    <b-row
                      ><b-col>
                        <b-form-group id="group-name" label-for="input-question">
                          <template v-slot:label>Sender Remarks </template>

                          <b-form-textarea
                            :disabled="isReceiver"
                            id="input-name"
                            type="text"
                            v-model="input.senderRemark"
                            placeholder="Enter Remarks"
                            :class="{
                              'box-shadow3 bg-light text-dark': !isDarkMode,
                              'bg-dark text-light placeholder-light': isDarkMode
                            }"
                          ></b-form-textarea>
                          <b-form-text id="input-description-help">Remarks must be more than 1 characters</b-form-text>
                          <b-form-invalid-feedback id="input-name-invalid"
                            >Please enter Remarks .</b-form-invalid-feedback
                          >
                        </b-form-group></b-col
                      ></b-row
                    >
                  </b-col>
                  <b-col lg="5" sm="12" v-if="isReceiver"
                    ><b-row
                      ><b-col>
                        <template labelfor="input-atf"> Qty By Receiver </template>
                        <b-form-input
                          id="input-weightage"
                          :disabled="input._id ? true : false"
                          placeholder="enter quantity"
                          v-model="input.quantityByReceiver"
                          type="number"
                          step="0.01"
                          :class="{
                            'box-shadow3 bg-light text-dark': !isDarkMode,
                            'bg-dark text-light placeholder-light': isDarkMode
                          }"
                        ></b-form-input> </b-col
                      ><b-col>
                        <b-form-group v-if="isReceiver" id="group-products" label-for="input-products">
                          <a v-if="input.receiverImage" :href="input.receiverImage" target="_blank">
                            <b-img
                              thumbnail
                              width="100"
                              v-if="input.receiverImage"
                              :src="input.receiverImage"
                              fluid
                            ></b-img>
                          </a>
                          <template v-slot:label>
                            Upload Receiver Side
                            <span class="text-danger">*</span>
                          </template>
                          <input type="file" @change="uploadFile($event, 'receiver', index)" /> </b-form-group></b-col
                    ></b-row>
                    <b-row
                      ><b-col>
                        <b-form-group id="group-name" label-for="input-question">
                          <template v-slot:label> Receiver Remarks </template>

                          <b-form-textarea
                            id="input-name"
                            type="text"
                            v-model="input.receiverRemark"
                            placeholder="Enter Remarks"
                            :class="{
                              'box-shadow3 bg-light text-dark': !isDarkMode,
                              'bg-dark text-light placeholder-light': isDarkMode
                            }"
                          ></b-form-textarea>
                          <b-form-text id="input-description-help">Remarks must be more than 1 characters</b-form-text>
                          <b-form-invalid-feedback id="input-name-invalid"
                            >Please enter Remarks .</b-form-invalid-feedback
                          >
                        </b-form-group></b-col
                      ></b-row
                    ></b-col
                  >
                  <b-col lg="2" sm="12" class="align-self-end mb-4">
                    <b-button
                      class="mb-4"
                      block
                      size="md"
                      @click="remove(input._id)"
                      :variant="isDarkMode ? 'outline-danger' : 'danger'"
                      ><font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />Delete</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col>
              <b-button @click="add(asset)" :variant="isDarkMode ? 'outline-primary' : 'primary'">Add Asset</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button
                v-if="!isCompleted"
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button
                v-if="isReceiver && !isCompleted"
                class="ml-2"
                @click="markReceived"
                size="sm"
                :variant="isDarkMode ? 'outline-primary' : 'primary'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />Mark Complete
              </b-button>
              <b-button
                v-if="!isCompleted"
                class="ml-2"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/atf` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import Assettransfer from '@/model/role';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapState } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
  name: 'AssettransferFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    assettransferType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          assetId: '',
          assetDescription: '',
          quantityBySender: '',
          quantityByReceiver: '',
          senderRemark: '',
          receiverRemark: '',
          condition: '',
          senderImage: null,
          receiverImage: null
        }
      ],
      formLoaded: false,
      assettransfer: [],
      selectedFromStore: null,
      form: {
        fromStoreId: null,
        toStoreId: null,
        receiptImage: null
      },
      assettransferEnabled: _.reduce(
        Assettransfer.assettransferEnabled,
        (result, value, key) => {
          result.push({ value, text: _.capitalize(key) });
          return result;
        },
        []
      )
    };
  },
  validations() {
    const formValidation = {
      form: {
        required
      },
      assettransfer: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.form.required = required;
      formValidation.assettransfer.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.list({ router });
    this.$nextTick(async () => {
      if (!this.storeByUser.length) {
        this.listByUser({ router });
      } else if (this.formType === 'new') {
        this.form.fromStoreId = this.storeByUser[0].storeid;
      }
      if (!this.storeList.length) {
        this.listStore({ router });
      }
      if (this.formType === 'new') {
        if (this.assettransferType === 'assettransfer') {
          this.form.id = Assettransfer.assettransfer.id;
        }
        this.form.status = Assettransfer.assettransferEnabled;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    ...mapState('assettransfer', ['assetList', 'loading', 'asset']),
    ...mapState('store', ['storeByUser', 'storeList']),
    ...mapState('darkMode', ['isDarkMode']),
    metaDescription() {
      return this.formType === 'new' ? 'Add new Asset' : 'Update Asset';
    },
    fromStoreList() {
      if (this.form.toStoreId) {
        return this.storeByUser.filter(store => store.storeid !== this.form.toStoreId);
      }
      return this.storeByUser;
    },
    toStoreList() {
      if (this.form.fromStoreId) {
        return this.storeList.filter(store => store.storeid !== this.form.fromStoreId);
      }
      return this.storeList;
    },
    isReceiver() {
      return this.storeByUser.map(store => store.storeid).includes(this.form.toStoreId);
    },
    isCompleted() {
      return this.form.markReceived || false;
    }
  },
  methods: {
    ...mapActions('assettransfer', ['listAssettransfer', 'getOne', 'postOne', 'patchOne']),
    ...mapActions('assettransfer', ['list', 'uploadDocument']),
    ...mapActions('store', ['listByUser', 'listStore']),
    onAssetIdChange(value, index) {
      this.inputs[index].assetId = value;
      const findAssetDescription = this.assetList.filter(asset => asset.assetId === value);
      if (findAssetDescription.length > 0) {
        this.inputs[index].assetDescription = findAssetDescription[0].assetDescription;
      }
    },
    onAssetDescriptionChange(value, index) {
      this.inputs[index].assetDescription = value;
      const findAssetId = this.assetList.filter(asset => asset.assetDescription === value);
      if (findAssetId.length > 0) {
        this.inputs[index].assetId = findAssetId[0].assetId;
      }
    },
    uploadFile(event, field, index) {
      this.file = event.target.files[0];
      const storeId = this.form.fromStoreId ? this.form.fromStoreId : this.storeByUser[0].storeid;
      const fileName = `${storeId}/${moment().unix()}`;
      this.uploadDocument({ file: this.file, fileName });
      const s3Url = 'https://spencer-atf.s3.ap-south-1.amazonaws.com';
      if (field === 'receipt') {
        this.form.receiptImage = `${s3Url}/${fileName}`;
      } else if (field === 'sender') {
        this.inputs[index].senderImage = `${s3Url}/${fileName}`;
      } else if (field === 'receiver') {
        this.inputs[index].receiverImage = `${s3Url}/${fileName}`;
      }
    },
    formatter(name) {
      return name.toUpperCase().split(/ /g).join('_');
    },
    markReceived() {
      const assettransfer = {
        fromStoreId: this.form.fromStoreId,
        toStoreId: this.form.toStoreId,
        receiptImage: this.form.receiptImage,
        markReceived: true,
        atfDetails: this.inputs.map(input => ({
          assetId: input.assetId,
          assetDescription: input.assetDescription,
          quantityBySender: input.quantityBySender,
          quantityByReceiver: input.quantityByReceiver,
          conditionOfAsset: input.condition,
          senderRemarks: input.senderRemark,
          receiverRemarks: input.receiverRemark,
          senderImage: input.sendrImage,
          receiverImage: input.receiverImage
        }))
      };
      this.$emit('edit', { assettransfer });
      return false;
    },
    onSubmit() {
      this.$v.$touch();
      const assettransfer = {
        fromStoreId: this.form.fromStoreId,
        toStoreId: this.form.toStoreId,
        receiptImage: this.form.receiptImage,
        atfDetails: this.inputs.map(input => ({
          assetId: input.assetId,
          assetDescription: input.assetDescription,
          quantityBySender: input.quantityBySender,
          quantityByReceiver: input.quantityByReceiver,
          conditionOfAsset: input.condition,
          senderRemarks: input.senderRemark,
          receiverRemarks: input.receiverRemark,
          senderImage: input.senderImage,
          receiverImage: input.receiverImage
        }))
      };
      if (this.formType === 'new') {
        this.$emit('add', { assettransfer });
      } else {
        this.$emit('edit', { assettransfer });
      }
      return false;
    },
    add() {
      this.inputs.push({
        assetId: '',
        assetDescription: '',
        quantityBySender: '',
        quantityByReceiver: '',
        senderRemark: '',
        file: '',
        condition: ''
      });
    },
    remove(index, checkpointId) {
      if (checkpointId) {
        this.checkpointsDeleted.push(checkpointId);
      }

      this.inputs.splice(index, 1);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    storeByUser(newValue) {
      if (newValue.length > 0 && !this.form.fromStoreId) {
        this.form.fromStoreId = newValue[0].storeid;
      }
    },
    asset(newValue) {
      if (newValue.id) {
        this.formLoaded = true;
        this.form.fromStoreId = newValue.fromStoreId;
        this.form.toStoreId = newValue.toStoreId;
        this.form.receiptImage = newValue.receiptImage;
        this.form.markReceived = newValue.markReceived;
        this.inputs = newValue.atfDetails.map(input => ({
          assetId: input.assetId,
          assetDescription: input.assetDescription,
          quantityBySender: input.quantityBySender,
          quantityByReceiver: input.quantityByReceiver,
          condition: input.conditionOfAsset,
          senderRemark: input.senderRemarks,
          receiverRemark: input.receiverRemarks,
          senderImage: input.senderImage,
          receiverImage: input.receiverImage
        }));
        this.$v.$touch();
        this.$v.$reset();
      }
    },
    assetList(newValue) {
      this.assetIdList = newValue.map(asset => asset.assetId);
      this.documentUrl = newValue.documentUrl;
      this.assetTransferList = newValue.map(asset => asset.assetDescription);
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
