<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
        @submit.stop.prevent="onSubmit"
      >
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label>
                CheckList Name
                <span class="text-danger">*</span>
              </template>

              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-name"
                type="text"
                v-model="form.name"
                placeholder="Enter CheckList Name"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>

              <b-form-invalid-feedback id="input-name-invalid">Please enter checklist name.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-product" label-for="input-product">
              <template v-slot:label>
                Active
                <span class="text-danger">*</span>
              </template>

              <multiselect
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
                id="input-status"
                placeholder="Select Active Type"
                v-model="form.active"
                :options="['True', 'False']"
              ></multiselect>

              <b-form-invalid-feedback id="input-status-invalid"
                >Please select valid active type.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Select Date
                <span class="text-danger">*</span>
              </template>
              <b-row>
                <b-col sm="12" lg="6">
                  <b-input-group class="box-shadow2 mb-2">
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        id="start-datepicker"
                        placeholder="Start Date"
                        :min="minDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        v-model="form.startDate"
                        class="box-shadow3"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-form-input
                      id="example-input"
                      v-model="form.startDate"
                      type="text"
                      placeholder="Start Date"
                      autocomplete="off"
                      :class="{
                        '': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group class="box-shadow2 mb-2">
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        id="end-datepicker"
                        placeholder="End Date"
                        :min="minDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        v-model="form.endDate"
                        class="box-shadow3"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-form-input
                      id="example-input"
                      v-model="form.endDate"
                      type="text"
                      placeholder="End Date"
                      autocomplete="off"
                      :class="{
                        '': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-frequency" label-for="input-frequency">
              <template v-slot:label> Frequency </template>

              <multiselect
                id="input-frequency"
                :options="['ONE-TIME', 'ANYTIME', 'DAILY', 'WEEKLY', 'BIWEEKLY', 'MONTHLY', 'HALF-YEARLY', 'YEARLY']"
                v-model="form.frequency"
                placeholder="How Frequently report need to be submitted."
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <div class="calender">

        </div> -->
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-name" label-for="input-name">
              <template v-if="formType === 'new'" v-slot:label>
                Set Time limit
                <span class="text-danger">*</span>
              </template>
              <template v-else v-slot:label>
                Update Time limit
                <span class="text-danger">*</span>
              </template>
              <div>
                <b-form-timepicker
                  v-model="form.activeTillTime"
                  id="timepicker-buttons"
                  now-button
                  label-now-button="Select Current Time"
                  reset-button
                  label-close-button="select"
                  locale="en"
                  placeholder="select time"
                  :class="{
                    'box-shadow3': !isDarkMode,
                    'bg-dark text-light placeholder-light': isDarkMode
                  }"
                ></b-form-timepicker>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Store Format
                <span class="text-danger">*</span>
              </template>

              <multiselect
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="form.formats"
                placeholder="Select Store Format"
                label="text"
                :options="formats"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Region Ids
                <span class="text-danger">*</span>
              </template>

              <multiselect
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="regionids"
                placeholder="Select region Ids"
                label="text"
                :options="regions"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Cluster Ids
                <span class="text-danger">*</span>
              </template>
              <multiselect
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="clusterids"
                placeholder="Select Cluster Ids"
                label="text"
                :options="clusters"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Store Ids
                <span class="text-danger">*</span>
              </template>
              <b-row>
                <b-col sm="12">
                  <multiselect
                    v-model="storeids"
                    ref="multiselect"
                    tag-placeholder="Add this as new tag"
                    placeholder="Select Store Ids"
                    label="text"
                    track-by="value"
                    :options="stores"
                    :multiple="true"
                    :taggable="true"
                    @tag="addTag"
                    :class="{
                      'box-shadow3 bg-light text': !isDarkMode,
                      darkMode: isDarkMode
                    }"
                  ></multiselect>
                </b-col>
                <b-col>
                  <b-button
                    class="box-shadow2"
                    @click="uploadStores"
                    :variant="isDarkMode ? 'outline-success' : 'success'"
                    ><font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload Stores</b-button
                  >
                </b-col>
              </b-row>
              <b-form-text id="input-role-help"
                >Note: The first selected store id will be the primary store id of CheckList for all
                operations.</b-form-text
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Role
                <span class="text-danger">*</span>
              </template>

              <multiselect
                id="input-role"
                v-model="form.role"
                placeholder="Select role"
                label="text"
                ref="multiselect"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :options="role"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>

              <b-form-group id="group-role" label-for="input-role" disabled>
                <!-- <template v-slot:label>
              Permission
              <span class="text-danger">*</span>
            </template> -->

                <multiselect
                  id="input-permission"
                  v-if="isVisible"
                  v-model="form.permission"
                  placeholder="Select Permission"
                  label="text"
                  :options="permission"
                  class="box-shadow3"
                ></multiselect>
              </b-form-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group id="group-role" label-for="input-role" disabled>
          <!-- <template v-slot:label>
            Notify To
            <span class="text-danger">*</span>
          </template> -->

          <multiselect
            ref="multiselect"
            v-if="isVisible"
            tag-placeholder="Add this as new tag"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            id="input-role-help"
            v-model="notifyTo"
            placeholder="Select Nofity To "
            label="text"
            :options="role"
            :class="{
              'box-shadow3 bg-light text': !isDarkMode,
              darkMode: isDarkMode
            }"
          >
          </multiselect>
        </b-form-group>

        <div class="input wrapper items-center" v-for="(input, email) in emails" :key="email">
          <b-row class="mb-3">
            <b-col>
              <b-row>
                <b-col lg="6">
                  <template labelfor="input-category"> Share To </template>
                  <b-form-input
                    id="input-email"
                    v-model="input.email"
                    placeholder="Enter Email Address"
                    type="email"
                    aria-describedby="input-live-help input-live-feedback"
                    value="ok"
                    :class="{
                      'box-shadow3 bg-light text-dark': !isDarkMode,
                      'bg-dark text-light placeholder-light': isDarkMode
                    }"
                    class="box-shadow3"
                  ></b-form-input>
                </b-col>

                <b-col sm="0">
                  <b-button
                    class="mt-4 box-shadow3"
                    @click="removeEmail(email)"
                    v-show="email || (!email && emails.length > 1)"
                    :variant="isDarkMode ? 'outline-danger' : 'danger'"
                    >Delete</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-button
            class="box-shadow2"
            @click="addEmail(email)"
            v-show="email == emails.length - 1"
            :variant="isDarkMode ? 'outline-primary' : 'primary'"
            ><font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add</b-button
          >
        </div>
        <b-col cols="0" class="mb-3">
          <div class="upload">
            <b-button class="box-shadow2" :variant="isDarkMode ? 'outline-success' : 'success'" @click="uploadEmail"
              ><font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload Emails</b-button
            >
          </div>
        </b-col>

        <!-- <template v-if="storeIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ storeIdError }}</b-col>
          </b-row>
        </template> -->
        <template v-if="regionIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ regionIdError }}</b-col>
          </b-row>
        </template>
        <template v-if="clusterIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ clusterIdError }}</b-col>
          </b-row>
        </template>

        <b-form-group>
          <h4 class="page-title text-center">Checkpoints</h4>
          <div class="mt-2 input wrapper items-center" v-for="(input, checkpoint) in inputs" :key="checkpoint">
            <b-row class="border-bottom pb-3 mb-3">
              <b-col cols="12">
                <b-row>
                  <b-col sm="12" lg="3" class="mt-3">
                    <template labelfor="input-category"> Category </template>

                    <b-form-input
                      id="input-category"
                      :disabled="input._id ? true : false"
                      v-model="input.category"
                      type="text"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      placeholder="Enter Category"
                    ></b-form-input>
                  </b-col>

                  <b-col sm="12" lg="3" class="mt-3">
                    <template labelfor="input-subcategory"> Sub Category </template>
                    <b-form-input
                      id="input-subcategory"
                      :disabled="input._id ? true : false"
                      v-model="input.subCategory"
                      type="text"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      placeholder="Enter Sub Category"
                    ></b-form-input>
                  </b-col>

                  <b-col sm="12" lg="3" class="mt-3">
                    <template labelfor="input-question"> Question </template>
                    <b-form-input
                      id="input-question"
                      :disabled="input._id ? true : false"
                      v-model="input.questionText"
                      type="text"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      placeholder="Enter Question"
                    ></b-form-input>
                  </b-col>
                  <b-col sm="12" lg="3" class="mt-3">
                    <template labelfor="input-question"> Weightage </template>
                    <b-form-input
                      id="input-weightage"
                      :disabled="input._id ? true : false"
                      v-model="input.weightage"
                      type="number"
                      step="0.01"
                      :class="{
                        'box-shadow3 bg-light text-dark': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      placeholder="Enter Weightage"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="3" class="mt-3" v-if="formType != 'new'">
                    <template labelfor="input-checkpointType"> Question Type </template>
                    <multiselect
                      id="input-checkpointType"
                      :disabled="input._id ? true : false"
                      placeholder="Select Question Type"
                      v-model="input.checkpointType"
                      :options="['TARGET', 'BOOLEAN']"
                      :class="{
                        'box-shadow3 ': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    >
                    </multiselect>
                  </b-col>
                  <b-col sm="12" lg="3" class="mt-3" v-if="formType === 'new' && input.weightage != null">
                    <template labelfor="input-checkpointType"> Question Type </template>
                    <multiselect
                      id="input-checkpointType"
                      disabled
                      placeholder="Select Question Type"
                      v-model="input.checkpointType"
                      :options="['TARGET', 'BOOLEAN']"
                      v-init="(input.checkpointType = 'BOOLEAN')"
                      :class="{
                        'box-shadow3 ': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    ></multiselect>
                  </b-col>
                  <b-col sm="12" lg="3" class="mt-3" v-if="formType === 'new' && input.weightage == null">
                    <template labelfor="input-checkpointType"> Question Type </template>
                    <multiselect
                      id="input-checkpointType"
                      :disabled="input._id ? true : false"
                      placeholder="Select Question Type"
                      v-model="input.checkpointType"
                      :options="['TARGET', 'BOOLEAN']"
                      :class="{
                        'box-shadow3 ': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    >
                    </multiselect>
                  </b-col>

                  <b-col sm="12" lg="3" class="mt-3">
                    <template labelfor="input-critical"> Critical </template>

                    <multiselect
                      id="input-critical"
                      :disabled="input._id ? true : false"
                      placeholder="Select Critical Type"
                      v-model="input.isCritical"
                      :options="viewType"
                      :class="{
                        'box-shadow3 ': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    ></multiselect>
                  </b-col>
                  <b-col sm="12" lg="3" class="mt-3">
                    <template labelfor="input-image">Img Mandatory </template>

                    <multiselect
                      id="input-image"
                      :disabled="input._id ? true : false"
                      placeholder="Set Image Mandatory"
                      v-model="input.isImageMandatory"
                      :options="viewType"
                      :class="{
                        'box-shadow3 ': !isDarkMode,
                        darkMode: isDarkMode
                      }"
                    ></multiselect>
                  </b-col>
                  <b-col class="mt-3" sm="12" lg="3">
                    <b-button
                      class="mt-4 box-shadow3"
                      @click="remove(checkpoint, input._id)"
                      v-show="checkpoint || (!checkpoint && inputs.length > 1)"
                      :variant="isDarkMode ? 'outline-danger' : 'danger'"
                    >
                      <font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />Delete</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-button
              class="box-shadow2"
              @click="add(checkpoint)"
              v-show="checkpoint == inputs.length - 1"
              :variant="isDarkMode ? 'outline-primary' : 'primary'"
              ><font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />Add CheckPoints</b-button
            >
          </div>
          <div class="upload">
            <b-button class="box-shadow2" :variant="isDarkMode ? 'outline-success' : 'success'" @click="showUpload"
              ><font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload Checkpoints</b-button
            >
          </div>

          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button
                class="box-shadow3"
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button
                class="ml-2 box-shadow3"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/checklist` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { mapGetters, mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Checklist from '@/model/checklist';
import configService from '@/services/configService';
import router from '@/router';
import Multiselect from 'vue-multiselect';

const init = {
  mounted: el => el.init()
};

export default {
  name: 'CheckListFormBox',
  directives: {
    init
  },
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    checklistType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      emails: [
        {
          email: ''
        }
      ],
      inputs: [
        {
          name: ''
        }
      ],
      title: '',
      email: [],
      formLoaded: true,
      isVisible: false,
      checkpointsDeleted: [],
      regions: [],
      clusters: [],
      formats: [],
      stores: [],
      role: [],
      Permission: [],
      storeids: [],
      clusterids: [],
      checkpoint: [],
      regionids: [],
      notifyTo: [],
      form: {
        name: null,
        active: 'True',
        startDate: null,
        endDate: null,
        storeids: [],
        regionids: [],
        formats: [],
        email: [],
        notifyTo: [],
        clusterids: [],
        Permission: [],
        role: [],
        checkpoint: [],
        frequency: 'ONE-TIME',
        activeTillTime: null
      },
      storeIdError: null,
      clusterIdError: null,
      regionIdError: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      minDate: moment().format('YYYY-MM-DD'),
      checklistEnabled: _.reduce(
        Checklist.checklistEnabled,
        (result, value, key) => {
          result.push({ value, text: _.capitalize(key) });
          return result;
        },
        []
      ),
      viewType: ['true', 'false']
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      active: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.active.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listRole({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        if (this.checklistType === 'checklist') {
          this.form.id = Checklist.checklist._id; // eslint-disable-line
        }
        this.form.status = Checklist.checklistEnabled.active;
        this.formLoaded = false;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new checklist' : 'Update checklist';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('role', ['roles']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('checklist', ['loading', 'checklist']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList'])
  },
  methods: {
    ...mapActions('role', ['listRole']),
    ...mapActions('report', ['upload']),
    ...mapActions('checklist', ['list', 'getOne', 'postOne']),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),
    createCsvForCheckpoints() {
      const csv = `category,subCategory,questionText,weightage,questionType(In Uppercase),iscritical(In capitalize),isImageMandatory(In UpperCase)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Checkpoint.csv';
      anchor.click();
    },

    async showUpload() {
      await Vue.swal({
        title: 'Upload Checkpoints',
        text: 'Please select the file to upload',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Checkpoint file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsvForCheckpoints();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[3] != '') {
                    item[3] = parseFloat(item[3]);
                  }
                  if (item[3] === '') {
                    item[3] = null;
                  }
                  const type = item[4].toUpperCase();
                  if (type === 'TARGET') {
                    item[4] = 'TARGET';
                  }
                  return {
                    index: item[0],
                    subCategory: item[1],
                    questionText: item[2],
                    weightage: item[3],
                    checkpointType: item[4].toUpperCase(),
                    isCritical: item[5].toLowerCase(),
                    isImageMandatory: item[6].toLowerCase()
                  };
                });
                this.inputs = inputs;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    createCsvEmail() {
      const csv = `Email\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadEmail() {
      await Vue.swal({
        title: 'Upload file for emails..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your email file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsvEmail();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const emails = csvRow.slice(1, csvRow.length).map(item => {
                  if (!item[0].includes('@rpsg.in')) {
                    Vue.swal({
                      title: 'Invalid email',
                      text: `${item[0]} is not a valid email, please use only rpsg.in emails`,
                      icon: 'error',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                  } else {
                    return {
                      email: item[0]
                    };
                  }
                });
                this.emails = emails;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },
    createCsv() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${
                        item[0].charAt(0).toUpperCase() + item[0].slice(1)
                      } is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.storeids.filter(store => newAddingStore.includes(store.value));
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.storeids = [...this.storeids, ...this.abc];
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },

    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const storeids = [];
      this.storeids.map(({ value }) => storeids.push(value));

      const regions = [];
      this.regionids.map(({ value }) => regions.push(value));

      const clusters = [];
      this.clusterids.map(({ value }) => clusters.push(value));

      const formats = [];
      this.form.formats.map(({ value }) => formats.push(value));

      const role = [];
      const notifyTo = role;
      this.notifyTo.map(({ value }) => role.push(value));

      const roles = [];
      this.form.role.map(({ value }) => roles.push(value));

      // const checkpointsDeleted = [];

      const checkpointsAdded = this.inputs
        .filter(({ _id }) => !_id)
        .map(j => ({ ...j, isCritical: j.isCritical === 'true', isImageMandatory: j.isImageMandatory === 'true' }));

      const checklist = {
        name: this.form.name,
        active: this.form.active === 'True',
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        frequency: this.form.frequency,
        permissions: this.form.permission ? this.form.permission : [],
        storeids,
        roles: roles || [],
        clusters: clusters || [],
        notifyTo: notifyTo || [],
        regions: regions || [],
        formats: formats || [],
        checkpoints: this.inputs,
        checkpointsDeleted: this.checkpointsDeleted,
        checkpointsAdded: checkpointsAdded || [],
        shareTo: this.emails.map(i => i.email) || [],
        activeTillTime: this.form.activeTillTime ? `${moment().format('YYYY-MM-DD')}T${this.form.activeTillTime}` : null
      };
      for (let i = 0; i < checklist.shareTo.length; i++) {
        if (_.isEmpty(checklist.shareTo[i]) === true) {
          if (this.formType === 'new') {
            this.$emit('add', { checklist });
          } else {
            this.$emit('edit', { checklist });
          }
          return false;
        }
        if (!checklist.shareTo[i].includes('@rpsg.in')) {
          Vue.swal({
            title: 'Access Denied',
            text: `${checklist.shareTo[i]} is not a valid email address! Please use only rpsg.in email address.`,
            type: 'error',
            confirmButtonText: 'Ok',
            background: this.isDarkMode ? '#464d54' : '#ffffff',
            customClass: {
              title: this.isDarkMode ? 'text-light' : 'text-dark',
              content: this.isDarkMode ? 'text-light' : 'text-dark'
            }
          });
          return false;
        }
      }
      if (this.formType === 'new') {
        this.$emit('add', { checklist });
      } else {
        this.$emit('edit', { checklist });
      }
      return false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    add() {
      this.inputs.push({ category: '', subCategory: '', questionText: '' });
    },
    remove(index, checkpointId) {
      if (checkpointId) {
        this.checkpointsDeleted.push(checkpointId);
      }

      this.inputs.splice(index, 1);
    },
    addEmail() {
      this.emails.push({ email: '' });
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    }
  },
  watch: {
    checklist(newValue) {
      if (!this.checklist.id) {
        return;
      }
      // Loaded checklist, assign to form
      this.form.name = newValue.name;
      this.form.active = newValue.active;
      this.form.checkpointType = newValue.checkpointType;
      this.form.isCritical = newValue.isCritical === 'True';
      this.form.isImageMandatory = newValue.isImageMandatory === 'True';
      this.form.startDate = moment(newValue.startDate).format('YYYY-MM-DD');
      this.form.endDate = moment(newValue.endDate).format('YYYY-MM-DD');
      this.form.frequency = newValue.frequency;
      this.inputs = newValue.checkpoint;
      this.emails = newValue.shareTo.map(email => ({ email }));
      this.storeids = newValue.storeids.map(store => ({ text: store, value: store }));
      this.regionids = newValue.regions.map(region => ({ text: region, value: region }));
      this.notifyTo = newValue.notifyTo.map(({ name, _id }) => ({ text: name, value: _id }));
      this.clusterids = newValue.clusters.map(cluster => ({ text: cluster, value: cluster }));
      this.form.role = newValue.roles.map(({ name, _id }) => ({ text: name, value: _id }));
      this.form.formats = newValue.formats.map(format => ({ text: format, value: format }));
      this.form.activeTillTime = newValue.activeTillTime ? moment(newValue.activeTillTime).format('HH:mm:ss') : null;

      const foundNotify = this.notifyTo.find(i => i.value === newValue.roles[0]);
      if (foundNotify) {
        this.form.notifyTo = foundNotify;
      } else {
        this.form.notifyTo = { text: newValue.roles[0], value: newValue.roles[0] };
      }
      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    clusterList(newValue) {
      this.clusters = newValue;
    },
    regionList(newValue) {
      this.regions = newValue;
    },
    formatList(newValue) {
      this.formats = newValue;
    },
    roles(newValue) {
      this.role = newValue;
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
.upload {
  margin-top: 8px;
}
</style>
